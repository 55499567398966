<template>
  <CCard>
    <CCardHeader>
      <strong>{{ messages.tables.results }} </strong>
      <slot name="area"></slot>
      <slot name="period"></slot> / 
      <slot name="fiscalYear"></slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :items="tableData"
        :fields="fields"
        hover
        outlined
        border
        clickableRows
        :columnFilter="false"
        :tableFilter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
      />
    </CCardBody>
    <CCardFooter>
      <p class="incentives-note">
        {{ messages.dealerIncentiveEstimation.note1 }}
      </p>
      <p class="incentives-note">
        {{ messages.dealerIncentiveEstimation.note2 }}
      </p>
      <p class="incentives-note">
        {{ messages.dealerIncentiveEstimation.note3 }}
      </p>
    </CCardFooter>
  </CCard>
</template>

<script>
export default {
  name: "TableIncentives",
  data() {
    return {
      lang: null,
      styledItems: null,
      itemsUpperCased: null,
      user: null,
    };
  },
  computed: {
    items() {
      return this.tableData;
    },
    fields() {
      return [
        {
          key: "description",
          label:
            this.messages.dealerIncentiveEstimation.description.toUpperCase(),
        },
        {
          key: "targetPurchases",
          label:
            this.messages.dealerIncentiveEstimation.targetPurchases.toUpperCase(),
        },
        {
          key: "purchasesCount",
          label:
            this.messages.dealerIncentiveEstimation.purchasesCount.toUpperCase(),
        },
        {
          key: "purchasesCharge",
          label:
            this.messages.dealerIncentiveEstimation.purchasesCharge.toUpperCase(),
        },
        {
          key: "completion",
          label:
            this.messages.dealerIncentiveEstimation.completion.toUpperCase(),
        },
        {
          key: "incentive",
          label:
            this.messages.dealerIncentiveEstimation.incentive.toUpperCase(),
        },
        {
          key: "maxIncentive",
          label:
            this.messages.dealerIncentiveEstimation.maxIncentive.toUpperCase(),
        },
        {
          key: "incentiveDiff",
          label:
            this.messages.dealerIncentiveEstimation.incentiveDiff.toUpperCase(),
        },
      ];
    },
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
  },
};
</script>

<style lang="scss">
.incentives-note {
  font-size: 12px;
  font-style: italic;
  padding: 0 0.75rem;
  margin: 1em 0;
}
</style>
