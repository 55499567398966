var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(_vm.messages.tables.results)+" ")]),_vm._t("area"),_vm._t("period"),_vm._v(" / "),_vm._t("fiscalYear")],2),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.tableData,"fields":_vm.fields,"hover":"","outlined":"","border":"","clickableRows":"","columnFilter":false,"tableFilter":{
        label: _vm.messages.tables.filter,
        placeholder: `${_vm.messages.tables.search} ...`,
      },"cleaner":"","noItemsView":{
        noItems: _vm.messages.tables.noItems,
        noResults: _vm.messages.tables.noResults,
      }}})],1),_c('CCardFooter',[_c('p',{staticClass:"incentives-note"},[_vm._v(" "+_vm._s(_vm.messages.dealerIncentiveEstimation.note1)+" ")]),_c('p',{staticClass:"incentives-note"},[_vm._v(" "+_vm._s(_vm.messages.dealerIncentiveEstimation.note2)+" ")]),_c('p',{staticClass:"incentives-note"},[_vm._v(" "+_vm._s(_vm.messages.dealerIncentiveEstimation.note3)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }